import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { NetworkTypeContext, WalletAddressContext, Web3Context } from '../../context'
import CoreData from '../../methods/CoreData'
import LinkIcon from '../../images/freshBank/link.svg'
import styles from "./ViewTransation.module.scss";

export const ViewTransation = ({ txnHash }) => {
  const { t, i18n } = useTranslation()
  const { networkType } = useContext(NetworkTypeContext)

  return (
    txnHash &&
    <a
      className={styles.link}
      href={CoreData.getExplorerUrl(txnHash, networkType)}
      target="_blank"
      rel="noopener noreferrer">
      {t('CommonModal.ViewTxnOnExplorer')} <img src={LinkIcon} width="auto" height="16" className="d-inline-block align-top" alt="" />
    </a>
  )

}