import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Web3Context} from '../../context'

export const MoreToolTips = ({title, content, children, showMoreIcon = true}) => {
  const { t, i18n } = useTranslation()
  const { version } = useContext(Web3Context)

  return showMoreIcon ? ( 
    <OverlayTrigger 
      // defaultShow={true}
      overlay={
        <Tooltip 
          className={classNames({'more-toolTips': version === 'v2', 'v1-more-toolTips': version === 'v1'})}
        >
          {title && <div className="more-toolTips-hd">{title}</div>}
          <div className="more-toolTips-bd">{content}</div>
        </Tooltip>
      }
    >
      <div className="fd-moreWrap">
        <span className="fd-num">{children}</span>
        <span className="fd-more"></span>
      </div>
    </OverlayTrigger>
  ) : (
    <div className="fd-moreWrap" style={{paddingRight: 0}}>
      <span className="fd-num">{children}</span>
    </div>
  )
}