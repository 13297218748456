import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TorTooltipType = {
  line: "line",
  icon: "icon",
  dom: "dom"
}

const TorTooltip = ({
  title = null,
  desc = '',
  type = TorTooltipType.line,
  tooltipClassName = '',
  isShow = false,
  ...props
}) => {
  const { t, i18n } = useTranslation()
  const [show, setShow] = useState(isShow)


  const showHandler = (e) => {
    setShow(true)
  }

  const hideHandler = (e) => {
    !isShow && setShow(false)
  }

  const viewByTyle = type => {
    let com = <></>;

    switch (type) {
      case TorTooltipType.dom:
        com = <div
          className={classNames('tor-tooltip-without-line')}
          onMouseOver={showHandler}
          onMouseLeave={hideHandler}>
          <OverlayTrigger
            show={show}
            overlay={<Tooltip className={classNames('tor-tooltip', tooltipClassName)}>{desc}</Tooltip>}>
            {title}
          </OverlayTrigger>
        </div>
        break;

      case TorTooltipType.icon:
        com = <div
          className={
            classNames('tor-tooltip-Icon', {
              ['tor-tooltip-Icon-white']: props.iconColor === 'white'
            })}
          onMouseOver={showHandler}
          onMouseLeave={hideHandler}>
          <span>{title}</span>
          <OverlayTrigger
            show={show}
            overlay={<Tooltip className={classNames('tor-tooltip', tooltipClassName)}>{desc}</Tooltip>}>
            <span className={classNames('tor-tooltip-infoIcon')}></span>
          </OverlayTrigger>
        </div >
        break;

      default:
        // type === line
        com = <div
          className={classNames('tor-tooltip-Line')}
          onMouseOver={showHandler}
          onMouseLeave={hideHandler}>
          <OverlayTrigger
            show={show}
            overlay={<Tooltip className={classNames('tor-tooltip', tooltipClassName)}>{desc}</Tooltip>}>
            <span>{title}</span>
          </OverlayTrigger>
        </div>
        break;
    }

    return com;
  };

  return !desc ? title : viewByTyle(type);
}

export default TorTooltip
