import config from "./config";
import { BigNumber } from 'bignumber.js';
import UAuth from "@uauth/js";
import detectEthereumProvider from "@metamask/detect-provider";

export const globalUtils = {
	address0: "0x0000000000000000000000000000000000000000",
	getExchangeTitle: id => (Object.keys(config.exchangeTitles)[id]),
	loadABI: async url => {
		return await (await fetch(url)).json();
	},
	zero: new BigNumber(0),
	ONE: new BigNumber(1),
	initialActualBorrowValues: {
		actualBorrowA: new BigNumber(0),
		actualBorrowB: new BigNumber(0),
		debtRatio: new BigNumber(0)
	},
	initLeverageTimes: [{ key: "1x", value: 1 }],
	max: new BigNumber(2).pow(256).minus(1).toFixed(),
	normalDecimals: 6,
	DEFAULT_MAX_DECIMALS: 18,
	farmTopic: "0x1169c71f6ce3fbf1d6aae39931591c46ed51976eda9f851886fae319970482ff",
	ConnectStatus: {
		NO_WALLET: "noWallet",
		CONNECTED: "connected",
		DISCONNECTED: "disconnected",
		IDLE: "idle"
	},
	ADDRESS_PLACEHOLDER: "0x43D46D96157f497070BfD0725C34C4A9d1013292",
	ADDRESS_CONNECTED: "addressConnected",
	WALLET_CONNECTING: "walletConnecting",
	COME_FROM: "comeFrom",
	ComeFrom: {
		home: 0,
		banking: 1
	},
	NA: "N/A",
	ZERO_32: "0x0000000000000000000000000000000000000000000000000000000000000000",
	ADDRESS32_PREFIX: "0x000000000000000000000000",
	HEXSTRING_PREFIX: "0x",
	WEEK: 604800,
	TEN_MINUTES: 600,
	TWO_HOURS: 7200,
	HOURS: 3600,
	DAY: 86400,
	LiteralBlockNumber: {
		EAELIEST: "earliest",
		LATEST: "latest",
		PENDING: "pending"
	},
	_udAuth: null,
	getUDAuth: function () {
		if (!this._udAuth) {
			this._udAuth = new UAuth(config.udAuthConfig);
		}
		return this._udAuth;
	},
	ForgeStatus: {
		IDLE: 0,
		FORGING: 1,
		DONE: 2,
		CANNOT: 3,
		ERROR: 4
	},
	TransactionStatus: {
		IDLE: 0,
		IN_PROGRESS: 1,
		SUCCESS: 2,
		FAILURE: 3
	},
	SortDirection: {
		DEFAULT: 0,
		UP: 1,
		DOWN: 2,
		FORCE_DOWN: 3
	},
	getProvider: async function (walletTitle) {
		let provider = null;

		switch (walletTitle) {
			case "bitKeep":
				provider = window.bitkeep && window.bitkeep.ethereum;
				provider.fdWalletType = "metamask";
				break;

			case "tokenPocket":
				if (window.ethereum.isTokenPocket) {
					provider = window.ethereum;
					provider.fdWalletType = "metamask";
				}
				break;

			case "kucoinWallet":
				if (window.kucoin?.isKuCoinWallet) {
					provider = window.kucoin;
					provider.fdWalletType = "kucoinWallet";
				}
				break;

			default:
				provider = await detectEthereumProvider({ silent: true, timeout: 5000 });
				provider.fdWalletType = "metamask";
				break;
		}
		return provider;
	}
}