import WalletConnectProvider from "@walletconnect/web3-provider";
import { Subject, BehaviorSubject } from "rxjs";
import Web3 from 'web3'
import Config from "../utils/config";

const walletTypeChangeRequest = new Subject();

const getActiveWalletType = function() {
    return window.localStorage.getItem("walletinuse");
}

const setActiveWalletType = function(walletKey) {
    if (walletKey)
        window.localStorage.setItem("walletinuse", walletKey);
    else
        window.localStorage.removeItem("walletinuse");
}

const switchToWallet = function(walletKey) {
    walletTypeChangeRequest.next(walletKey);
}

const createMetamaskWeb3Provider = async function() {
    // console.info("Creating Metamask provider");
    return window.ethereum;
}

const createWalletConnectWeb3Provider = async function() {
    // console.info("Creating Wallet Connect provider");

     //  Create WalletConnect Provider
    let provider = new WalletConnectProvider(Config.walletConnect);

    return provider;
}

const switchDefaultChain = async (chainConfig) => {

    const { chainId, rpcUrls, chainName, blockExplorerUrls, nativeCurrency } = chainConfig;
  
    const ethereum = await createMetamaskWeb3Provider()
    if (typeof ethereum !== 'undefined') {
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        });
  
        if (window.navigator.userAgent.includes("TokenPocket")) {
          window.location.reload();
        }
  
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{ chainId: Web3.utils.toHex(chainId), rpcUrls, chainName, blockExplorerUrls, nativeCurrency }],
            });
            
          } catch (addError) {
            console.log(addError)
          }
        } else {
          console.log(switchError)
        }
      }
    } else {
      console.log('MetaMask is not installed, please install it first')
    }
}

export {
    walletTypeChangeRequest,
    switchToWallet,
    getActiveWalletType,
    setActiveWalletType,
    createMetamaskWeb3Provider,
    createWalletConnectWeb3Provider,
    switchDefaultChain
}