import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import styles from './TorToast.module.scss'

const TorToast = (props) => {
  const { t, i18n } = useTranslation()
  let toastTimer = null
  useEffect(() => {
    if (props.show) {
      toastTimer = setTimeout(() => {
        props.onClose()
      }, props.showMs || 5000)
    }
    return () => {
      clearTimeout(toastTimer)
    }
  }, [props.show])


  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      timeout={500}
      classNames="toastTransition"
      unmountOnExit
    >
      <div 
        className={classNames(styles.toast, {
          [styles.toastError]: props.type === 'error',
          [styles.toastSuccess]: props.type === 'success'
        }, props.torClassName)} 
      > 
        {props.children}
      </div>
    </CSSTransition>,
    document.getElementById('root')
  )
}


export default TorToast