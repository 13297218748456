import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import CoreData from '../../methods/CoreData'
import { ModalListTips } from '../../components/littleComponents'

export const ModalPercent = ({
  percent = 0, 
  tips,
  showTipsList = false,
  handlingFee = '',
  showDao = false,
  daoDiscount = ''
}) => {
  const { t, i18n } = useTranslation()

  const status = {
    normal: percent > 0 && percent <= 50,
    warning: percent > 50 && percent <= 85,
    error: percent > 85
  }

  const barInnerCSS = classNames({
    ['loanBarInner']: true,
    ['loanBarInner-normal']: status.normal,
    ['loanBarInner-yellow']: status.warning,
    ['loanBarInner-red']: status.error
  })

  return (
    <div className="loanUtilRate">
        <div className="title">
            <div className="label">{t('CommonModal.LoanPercentageUsed')}</div>
            <div className={classNames({
              ['value']: true,
              ['value-normal']: status.normal,
              ['value-yellow']: status.warning,
              ['value-red']: status.error
            })}>{percent}%</div>
        </div>
        <div className="loanBarOuter">
            <div className={barInnerCSS} style={{width: CoreData.fixedNaN(percent).toString() + '%'}}></div>
        </div>
        {tips && <div className="msg">{tips}</div>}
        {
          showTipsList &&
          <ModalListTips
            handlingFee={handlingFee}
            showDao={showDao}
            daoDiscount={daoDiscount}
          />
        }
    </div>
  )
}