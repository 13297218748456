import React, { useState } from "react";
import styles from "./Range.module.scss";

export const Range = ({
	min = 0,
	defaultValue = 0,
	onChange = null
}) => {
	const [value, setValue] = useState(min ? min * 100 : defaultValue);
	const offsetX = 3 * value / 100 + "em";

	const handleChange = event => {
		const v = Number(event.currentTarget.value);
		if (v > min * 100) {
			setValue(v);
			if (onChange) {
				return onChange(v);
			}
		}
	};

	return <div className={styles.sliderBlock}>
		<input
			type="range"
			// defaultValue={defaultValue}
			value={value}
			onChange={handleChange} />

		<div
			className={styles.slideThrought}
			style={{ width: value + "%" }} />

		<div
			className={styles.thumb}
			style={{ left: "calc(" + value + "% - " + offsetX + ")" }}>{value.toFixed(1)}%</div>
	</div>
};