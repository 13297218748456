import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'
import { ModalTips } from '../../components/littleComponents'

// 默认只有一个按钮关闭

export const ModalTipsAndCheckBox = (props) => {
  const { t, i18n } = useTranslation()

  const {
    type = 'error',
    content = '',
    checkId = 'fdCheck',
    onChange = () => {},
    checked = false,
  } = props

  return ( 
    <>
      <ModalTips 
          smallIconTips={true}
          showTitle={false}
          type={type} 
          content={content}
      />
      <div className="tipsCheckWrap">
          <input 
            type="checkbox"
            id={checkId}
            checked={checked}
            onChange={onChange}
          />
          <label htmlFor={checkId}>{t('CommonModal.IUnderstand')}</label>
      </div>
    </>
  )
}