import React from "react";
import classNames from 'classnames'
import styles from "./TwinLogo.module.scss";

const TwinLogo = ({
	logo1,
	logo2,
	mode = 1,
	small = false,
	large = false,
}) => {

	const logoLayout = () => {
		let cls = null;
		switch (mode) {
			case 1:
				cls = classNames({
					[styles.twinLogoLayout]: true,
					[styles.smallLayout]: small
				});
				break;
			case 2:
				cls = classNames({
					[styles.twinLogoLayoutMode2]: true,
					[styles.largeLayout]: large
				});
				break;
			case 3:
				cls = classNames({ [styles.twinLogoLayoutMode3]: true });
				break;
			case 4:
				cls = classNames({ [styles.twinLogoLayoutMode4]: true });
				break
		}
		return cls
	}

	const logo1Css = classNames({
		[styles.logo1]: true,
		[styles.small]: small,
		[styles.large]: large
	})

	const logo2Css = classNames({
		[styles.logo2]: true,
		[styles.small]: small,
		[styles.large]: large
	})



	return (
		<div className={logoLayout()}>
			<img className={logo1Css} src={logo1} />
			<img className={logo2Css} src={logo2} />
		</div>
	)
};

export default TwinLogo;