import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { IoInformationCircleOutline } from "@react-icons/all-files/io5/IoInformationCircleOutline"
import Loading from '../../images/freshBank/loading.svg'
import Success from '../../images/freshBank/success.svg'
import Error from '../../images/freshBank/error.svg'
import styles from "./ModalTips.module.scss";
import WarningTips from '../../images/freshBank/warningTips.svg'
import ErrorTips from '../../images/freshBank/errorTips.svg'

export const ModalTips = ({
  smallIconTips = false,
  type,
  showTitle = true,
  showIcon = true,
  title = '',
  content
}) => {
  const { t } = useTranslation()

  const renderTitle = () => {
    switch (type) {
      case 'success':
        return t('CommonModal.Success')
      case 'error':
        return t('CommonModal.Error')
      case 'warning':
        return t('CommonModal.Warning')
      case "loading":
        return t('CommonModal.PleaseWait')
    }
  }

  const renderIcon = () => {
    switch (type) {
      case 'approve':
        return <IoInformationCircleOutline className={styles.icon} />
      case 'loading':
        return <img src={Loading} alt="loading" style={{ width: "48px" }} />
      case 'success':
        return <img src={Success} alt="success" />
      case 'error':
        return <img src={Error} alt="Error" />
      // 
      case 'warningTips':
        return <img src={WarningTips} alt="WarningTips" />
      case 'errorTips':
        return <img src={ErrorTips} alt="ErrorTips" />
    }
  }

  return (
    <div className={classNames(styles.tipsLayout, {
      'smallIconTipsConainer': smallIconTips,
      'smallIconError': type === 'errorTips',
      'smallIconWarning': type === 'warningTips'
    })}>
      {showIcon && renderIcon()}
      {showTitle && <div className={styles.title}>{title ? title : renderTitle()}</div>}
      {content && <div className={styles.msg}>{content}</div>}
    </div>
  )
}