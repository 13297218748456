import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import styles from './ModalSelectAsset.module.scss'


export const ModalSelectAsset = (props) => {

  const { t, i18n } = useTranslation()

  let dropdownItem = props?.swapData?.map((item, index) => {
    return (
        <Dropdown.Item 
          className={styles.selectAssetItem}
          key={item.symbol} 
          active={item.symbol === props.swapAsset.symbol} 
          onClick={() => props.onClick(item)}
        >
            <img src={item?.logo}/>
            <span className={styles.symbol}>{item?.name}</span>
        </Dropdown.Item>
    )
  })


  return (
    <DropdownButton 
        variant="selectAsset" 
        id="dropdown-selectAsset-button" 
        title={
            <div className={styles.selectAssetContainer}>
                <img src={props?.swapAsset?.logo}/>
                <span className={styles.symbol}>{props?.swapAsset?.name}</span>
            </div>
        }
    >
      {dropdownItem}
    </DropdownButton>
  )
}