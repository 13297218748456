import classNames from "classnames";
import React, { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { Range } from "./Range";
import styles from "./ToggleAndSlider.module.scss";

export const ToggleAndSlider = ({
	id = "buttonGroup",
	toggleButtonNum = [],
	toggleSlider = false,
	toggleButtonOnChange = null,
	onSlide = toggleButtonOnChange,
	disabled = false,
	capo = 0,
	min = 0,
	cleanUp = false
}) => {
	const [radioValue, setRadioValue] = useState(0);
	const [showSlider, setShowSlider] = useState(false);

	const handleToggleSlider = event => {
		setShowSlider(!showSlider);
	}

	const handleToggle = event => {
		const val = Number(event.target.value)

		if (val === undefined || isNaN(val)) {
			return;
		}

		setRadioValue(val);
		if (toggleButtonOnChange) {
			toggleButtonOnChange(val)
		}
	};

	const handleSlide = val => {
		const v = val / 100;
		if (onSlide) {
			onSlide(v)
		}
	};

	return <ToggleButtonGroup
		name={id}
		type="radio"
		className={classNames(
			styles.btnGroup,
			disabled ? styles.disabled : null,
			cleanUp ? styles.cleanUp : null
		)}
		onClick={handleToggle}>
		{!showSlider && toggleButtonNum.map((item, index) => (
			<ToggleButton
				key={item.key}
				id={`radio-${item.id ?? item.key}`}
				type="radio"
				variant="outline-green"
				value={item.value}
				disabled={index < capo}
				checked={radioValue === item.value}>
				{item.key}
			</ToggleButton>
		))}

		{showSlider && <div className={styles.sliderBlock}>
			<Range
				// defaultValue={radioValue * 100}
				min={min}
				onChange={handleSlide} />
		</div>}

		{toggleSlider && <ToggleButton
			variant="outline-green"
			checked={showSlider}
			onClick={handleToggleSlider}>
			<img src="/images/slider.png" width="16px" />
		</ToggleButton>}
	</ToggleButtonGroup>;
};