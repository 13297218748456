import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import CoreData from '../../methods/CoreData'
import { NetworkTypeContext} from '../../context'
import { FaLink } from "@react-icons/all-files/fa/FaLink"
import Loading from '../../images/freshBank/loading.svg'

export const PendingHash = ({
  txnHash = null,
  pendingDesc = ''
}) => {
  const { t, i18n } = useTranslation()
  const { networkType } = useContext(NetworkTypeContext)

  return ( 
    <div className="tor-pendingHashWrap">
      <img src={Loading} width="auto" height="28" alt="loading" />
      <div className="inProgressMsg">
        {pendingDesc && <span className="pendingDesc">{pendingDesc}</span>}
        {
          txnHash &&
          <a className="txnLink" href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank" rel="noopener noreferrer">
            <FaLink />
          </a>
        }
      </div>
    </div>
  )
}