import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { NetworkTypeContext} from '../../context'
import Config from '../../utils/config'

export const ModalListTips = ({
  handlingFee = '',
  showDao = false,
  daoDiscount = ''
}) => {
  const { networkType } = useContext(NetworkTypeContext)
  const { t, i18n } = useTranslation()

  return (
    <ul className="modalListTips">
      <li>
        <span className="liHd">{t('CommonModal.MaximumSlippage')}:</span>
        <span className="liBd">2%</span>
      </li>
      <li>
        <span className="liHd">
          {t('CommonModal.ChainTypeHandlingFee')}:
        </span>
        <span className="liBd">0.3%</span>
      </li>
      <li>
        <span className="liHd">{t('CommonModal.HandlingFee')}:</span>
        <span className="liBd">{handlingFee}</span>
      </li>
      {
        showDao &&
        <li>
          <span className="liHd">{t('CommonModal.DAOHoldingDiscount')}:</span>
          <span className="liBd">{daoDiscount}</span>
        </li>
      }
    </ul>
  )
}