import React, {useContext} from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { Web3Context} from '../../context'

/*
    totalPages: number
    currentPage: number
    onChangePage: function
    prevNext: boolean
    firstLast: boolean
*/
export const PaginationComponent = ({
  totalPages,
  currentPage,
  prevNext,
  firstLast,
  onChangePage
}) => {
  const { version } = useContext(Web3Context)
  const itemNum = isMobile ? 2 : 4

  let from = Math.max(currentPage - 2, 1)
  const to = Math.min(from + itemNum, totalPages)
  if (to == totalPages) {
      from = Math.max(to - itemNum, 1)
  }

  const list = []
  for (let i = from; i <= to; i++) {
      list.push(i)
  }

  const onUpdatePage = (index) => {
      if (index < 1) {
          onChangePage(1)
      } else if (index > totalPages) {
          onChangePage(totalPages)
      } else {
          onChangePage(index)
      }
  }

  return (
      <Pagination className="tor-pagination">
          {/* {firstLast !== false && <Pagination.First onClick={()=>onUpdatePage(1)} />} */}
          {prevNext !== false && <Pagination.Prev onClick={()=>onUpdatePage(currentPage-1)} />}
          {
              list.map((i) => 
                <Pagination.Item 
                  key={`PaginationItem-${i}`} 
                  active={currentPage === i} 
                  onClick={()=>onUpdatePage(i)}
                >
                  {i}
                </Pagination.Item>
              )
          }
          {prevNext !== false && <Pagination.Next onClick={()=>onUpdatePage(currentPage+1)}/>}
          {/* {firstLast !== false && <Pagination.Last onClick={()=>onUpdatePage(totalPages)} />} */}
      </Pagination>
  )
}