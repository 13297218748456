import React from 'react'

const LanguageContext = React.createContext({ language: 'en' })

const WalletAddressContext = React.createContext()

const WalletTypeContext = React.createContext()

const NetworkTypeContext = React.createContext()

const Web3Context = React.createContext()

const DataContext = React.createContext({
    handleUpdate: () => {}
})

const WalletModalContext = React.createContext()

export {
    LanguageContext,
    WalletAddressContext,
    WalletTypeContext,
    NetworkTypeContext,
    Web3Context,
    DataContext,
    WalletModalContext,
}
